import React from "react";

import { HOMEPAGE_URL } from "../constants/commonConstants";

const Footer = () => {
  return (
    <footer className="text-center">
      <p>
        <small>
          Copyright &copy; 2024 - UseDelight &nbsp; | &nbsp;
          <a href={`${HOMEPAGE_URL}/terms-of-use`}>Terms of Use</a>
          &nbsp; | &nbsp;
          <a href={`${HOMEPAGE_URL}/privacy-policy`}>Privacy Policy</a>
          &nbsp; | &nbsp;
          <a href={`${HOMEPAGE_URL}/cookies-policy`}>Cookies Policy</a>
        </small>
      </p>
    </footer>
  );
};

export default Footer;
