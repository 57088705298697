export const BASE_API_URL = "https://api.usedelight.com/api/v1";
// export const BASE_API_URL = "http://localhost:5000/api/v1";
export const LOGGED_IN = "loggedIn";
export const NAME = "name";
export const EMAIL = "email";
export const TOKEN = "token";
export const HOMEPAGE_URL = "https://usedelight.com";
export const VIDEO_SOURCE = "https://vid.usedelight.com";
export const RESULT_LIMIT = 20;
export const TAGS = [
  "beach",
  "city",
  "clouds",
  "desert",
  "forest",
  "islands",
  "mountains",
  "ocean",
  "rocks",
  "scenes",
  "sun",
  "water",
  "winter",
];
