import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useFormik } from "formik";
import { Button, Form, Card } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";

import FormContainer from "../components/FormContainer";
import { loginReset, register } from "../actions/userActions";
import Message from "../components/Message";
import Loader from "../components/Loader";
import "./LoginScreen.css";

const RegisterScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const userRegister = useSelector((state) => state.userRegister);
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error } = userRegister;
  const { userInfo } = userLogin;

  const searchParams = new URLSearchParams(location.search);
  const redirect = searchParams.get("redirect");

  useEffect(() => {
    if (userInfo && userInfo.token) {
      if (redirect === "upgrade") {
        navigate(`/${redirect}`);
      } else {
        navigate("/account");
      }
    }
  }, [navigate, redirect, userInfo]);

  useEffect(() => {
    dispatch(loginReset());
  }, [dispatch]);

  const schemaSignUp = yup.object({
    displayName: yup.string().required("Display name is required"),
    emailSignUp: yup
      .string()
      .required("Email is required")
      .email("Invalid Email"),
    passwordSignUp: yup
      .string()
      .required("Password is required")
      .min(6, "Password must be at least 6 chars"),
    confirmPasswordSignUp: yup
      .string()
      .required("Confirm Password is required")
      .oneOf([yup.ref("passwordSignUp"), null], "Password must match"),
  });

  const signupHandler = async (name, email, password) => {
    dispatch(register(name, email, password));
  };

  const suFormik = useFormik({
    initialValues: {
      displayName: "",
      emailSignUp: "",
      passwordSignUp: "",
      confirmPasswordSignUp: "",
    },
    validationSchema: schemaSignUp,
    onSubmit: (values) =>
      signupHandler(
        values.displayName,
        values.emailSignUp,
        values.passwordSignUp
      ),
  });

  return (
    <FormContainer>
      <Card className="login-card shadow-sm border-0 p-3">
        <Card.Body>
          <h2 className="text-center mb-4">Create Account</h2>
          {error && (
            <Message variant="danger">
              {error === "email already exists"
                ? "Email already exists. Please try to sign-in."
                : error}
            </Message>
          )}
          {loading && <Loader />}
          <Form noValidate onSubmit={suFormik.handleSubmit}>
            <Form.Group controlId="displayName" className="mb-3">
              <Form.Label>Display Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your display name"
                name="displayName"
                className="rounded-pill"
                isInvalid={
                  suFormik.touched.displayName && suFormik.errors.displayName
                }
                onChange={suFormik.handleChange}
                onBlur={suFormik.handleBlur}
                value={suFormik.values.displayName}
              />
              <Form.Control.Feedback type="invalid">
                {suFormik.errors.displayName}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="emailSignUp" className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                name="emailSignUp"
                className="rounded-pill"
                isInvalid={
                  suFormik.touched.emailSignUp && suFormik.errors.emailSignUp
                }
                onChange={suFormik.handleChange}
                onBlur={suFormik.handleBlur}
                value={suFormik.values.emailSignUp}
              />
              <Form.Control.Feedback type="invalid">
                {suFormik.errors.emailSignUp}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="passwordSignUp" className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Create a password"
                name="passwordSignUp"
                className="rounded-pill"
                isInvalid={
                  suFormik.touched.passwordSignUp && suFormik.errors.passwordSignUp
                }
                onChange={suFormik.handleChange}
                onBlur={suFormik.handleBlur}
                value={suFormik.values.passwordSignUp}
              />
              <Form.Control.Feedback type="invalid">
                {suFormik.errors.passwordSignUp}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="confirmPasswordSignUp" className="mb-4">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm your password"
                name="confirmPasswordSignUp"
                className="rounded-pill"
                isInvalid={
                  suFormik.touched.confirmPasswordSignUp &&
                  suFormik.errors.confirmPasswordSignUp
                }
                onChange={suFormik.handleChange}
                onBlur={suFormik.handleBlur}
                value={suFormik.values.confirmPasswordSignUp}
              />
              <Form.Control.Feedback type="invalid">
                {suFormik.errors.confirmPasswordSignUp}
              </Form.Control.Feedback>
            </Form.Group>

            <div className="d-grid gap-2">
              <Button
                variant="primary"
                type="submit"
                className="rounded-pill py-2 mb-3"
                disabled={loading}
              >
                Create Account
              </Button>
            </div>

            <div className="text-center">
              <LinkContainer to={redirect ? `/login?redirect=${redirect}` : "/login"}>
                <Button variant="link" className="text-decoration-none">
                  Already have an account? Sign in
                </Button>
              </LinkContainer>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </FormContainer>
  );
};

export default RegisterScreen;
