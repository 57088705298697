import React from 'react';
import * as constants from '../constants/commonConstants';

const FilterBar = ({ activeTag, onTagFilter, onNoTagFilter }) => {
  const tagFilter = constants.TAGS.map((tag) => (
    <button
      className={activeTag === tag ? "btn-filter-tag active" : "btn-filter-tag"}
      key={tag}
      onClick={() => onTagFilter(tag)}
    >
      {tag}
    </button>
  ));

  return (
    <div className="filters">
      <span className="controls-label">Filters:</span>
      {tagFilter}
      <button
        className={
          activeTag === "no-tag"
            ? "btn-filter-no-tags active"
            : "btn-filter-no-tags"
        }
        id="btn-filter-no-tags"
        onClick={onNoTagFilter}
      >
        (no tag)
      </button>
    </div>
  );
};

export default FilterBar;
