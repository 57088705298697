import React, { useState } from "react";
import { Multiselect } from "multiselect-react-dropdown";

import "./Video.css";
import * as constants from "../constants/commonConstants";

const Video = (props) => {
  const [validTags, setValidTags] = useState(constants.TAGS);
  const [saveNeeded, setSaveNeeded] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);

  const arrayEquals = (a, b) => {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    );
  };

  const updateSelectedTags = (selectedList) => {
    if (!arrayEquals(selectedTags, selectedList)) {
      setSaveNeeded(true);
      setSelectedTags(selectedList);
    }
  };

  const onSelect = (selectedList, selectedItem) => {
    updateSelectedTags(selectedList);
  };

  const onRemove = (selectedList, removedItem) => {
    updateSelectedTags(selectedList);
  };

  const onSave = async () => {
    try {
      setSaveNeeded(false);
      const response = await fetch(
        `${constants.BASE_API_URL}/videos/${props.id}`,
        {
          method: "PUT",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${props.token}`,
          },
          body: JSON.stringify({ tags: selectedTags }),
        }
      );

      const result = await response.json();
      if (result.success === true) {
        setSaveNeeded(false);
      }
    } catch (err) {
      console.error(err);
      setSaveNeeded(true);
    }
  };

  return (
    <div className="video">
      <img
        src={`${constants.VIDEO_SOURCE}/previews/${props.preview}`}
        alt={props.videoName}
        onClick={() =>
          props.previewVideo({
            videoId: props.id,
            videoIsActive: props.videoIsActive,
            videoName: props.videoName,
            videoTitle: props.videoTitle,
            videoAuthor: props.videoAuthor,
            videoFileSource: `${constants.VIDEO_SOURCE}/videos/${props.fileName}`,
            videoDuration: props.videoDuration,
            selectedCount: props.selectedCount,
            favoriteCount: props.favoriteCount,
            dislikeCount: props.dislikeCount,
            videoTags: props.videoTags
          })
        }
      />
      <div className={props.videoIsActive ? "video-info" : "video-info inactive"}>
        <div className="video-name">
          <span className="label">Name:</span>
          {props.videoName}
        </div>
        <div className="video-title">
          <span className="label">Title:</span>
          {props.videoTitle}
        </div>
        <div className="video-author">
          <span className="label">Author:</span>
          {props.videoAuthor}
        </div>
        
        <div className="two-col-info">
          <div className="video-duration">
            <span className="label">Duration:</span>
            {props.videoDuration}s
          </div>
          <div className="video-rating">
            <div className="video-selected" title="Views">
              <span>{props.selectedCount}</span>
              <span className="material-icons">visibility</span>
            </div>
            <div className="video-favorite" title="Favorites">
              <span>{props.favoriteCount}</span>
              <span className="material-icons">favorite</span>
            </div>
            <div className="video-dislike" title="Dislikes">
              <span>{props.dislikeCount}</span>
              <span className="material-icons">thumb_down</span>
            </div>
          </div>
        </div>

        <div className="video-tags">
          <Multiselect
            id={props.id}
            options={validTags}
            selectedValues={props.videoTags}
            isObject={false}
            onSelect={onSelect}
            onRemove={onRemove}
            placeholder="Select tags"
            closeIcon="cancel"
            style={{
              chips: {
                background: "#52a7e0",
                color: "white",
                padding: "4px 8px",
                borderRadius: "4px",
                fontSize: "0.85rem"
              },
              searchBox: {
                border: "1px solid #e0e0e0",
                borderRadius: "6px",
                padding: "8px",
                marginTop: "8px"
              },
              optionContainer: {
                background: "white",
                border: "1px solid #e0e0e0",
                boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                zIndex: "100"
              },
              option: {
                color: "#2c3e50",
                padding: "8px 12px"
              }
            }}
          />
        </div>

        {saveNeeded && (
          <div className="btn-save">
            <button onClick={onSave}>Save Changes</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Video;
