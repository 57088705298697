import React from "react";
import { Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import "./Loader.css";

const Loader = ({ size = "md", variant = "primary", fullScreen = false }) => {
  const sizeMap = {
    sm: { width: "1.5rem", height: "1.5rem" },
    md: { width: "3rem", height: "3rem" },
    lg: { width: "4.5rem", height: "4.5rem" }
  };

  return (
    <div className={`loader-container ${fullScreen ? 'fullscreen' : ''}`}>
      <div className="loader-wrapper">
        <Spinner
          className="loader"
          animation="border"
          role="status"
          variant={variant}
          style={sizeMap[size]}
        >
          <span className="sr-only">Loading...</span>
        </Spinner>
        <div className="loader-pulse"></div>
      </div>
    </div>
  );
};

Loader.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  variant: PropTypes.string,
  fullScreen: PropTypes.bool
};

export default Loader;
