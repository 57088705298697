import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Container } from "react-bootstrap";

import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
import ForgotPasswordScreen from "./screens/ForgotPasswordScreen";
import GalleryScreen from "./screens/GalleryScreen";
import StatsScreen from "./screens/StatsScreen";
import Header from "./components/Header";
import Footer from "./components/Footer";
import SummaryScreen from "./screens/SummaryScreen";

const App = () => {
  return (
    <Router>
      <div>
        <Header />
        <main className="py-3">
          <Container>
            <Routes>
              <Route path="/" element={<GalleryScreen />} exact />
              <Route path="/summary" element={<SummaryScreen />} />
              <Route path="/stats" element={<StatsScreen />} />
              <Route path="/login" element={<LoginScreen />} />
              <Route path="/register" element={<RegisterScreen />} />
              <Route path="/forgotpassword" element={<ForgotPasswordScreen />} />
            </Routes>
          </Container>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
