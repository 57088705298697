import axios from "axios";

import { BASE_API_URL } from "../constants/commonConstants";
import {
  GET_SUMMARY_FAIL,
  GET_SUMMARY_REQUEST,
  GET_SUMMARY_SUCCESS,
} from "../constants/summaryConstants";
import { updateAccessToken } from "./userActions";

export const getSummary = () => async (dispatch, getState) => {
  const currState = getState();
  const userInfo = currState.userLogin.userInfo;

  dispatch({
    type: GET_SUMMARY_REQUEST,
  });

  try {
    const authorization = userInfo.token ? `Bearer ${userInfo.token}` : "";

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
      },
    };

    const { data } = await axios.post(
      `${BASE_API_URL}/summary`,
      {
        refreshToken: userInfo.refreshToken,
      },
      config
    );

    if (data.success === true) {
      dispatch({
        type: GET_SUMMARY_SUCCESS,
        payload: data.data,
      });

      if (data.token !== userInfo.token) {
        dispatch(updateAccessToken(data.token));
      }
    } else {
      dispatch({
        type: GET_SUMMARY_FAIL,
        payload: data.result.error,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_SUMMARY_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data.message
          : error.message,
    });
  }
};
