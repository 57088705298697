import React, { useEffect, useState } from "react";
import { Table, Form, InputGroup, Button, Collapse } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FaBoxOpen, FaCreditCard, FaSearch, FaSort, FaSortUp, FaSortDown, FaChevronDown, FaChevronRight } from "react-icons/fa";

import { getSummary } from "../actions/summaryActions";
import Loader from "../components/Loader";
import Message from "../components/Message";
import "./SummaryScreen.css";

const LoadingSkeleton = () => (
  <div>
    {[1, 2, 3].map((i) => (
      <div key={i} className="loading-skeleton" style={{ width: `${Math.random() * 40 + 60}%` }} />
    ))}
  </div>
);

const EmptyState = ({ message }) => (
  <div className="empty-state">
    <p>{message}</p>
  </div>
);

const StatCard = ({ title, value }) => (
  <div className="stat-card">
    <h4>{title}</h4>
    <div className="stat-value">{value}</div>
  </div>
);

const InstallationsTable = ({ installations }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="summary-section">
      <h3 
        onClick={() => setIsOpen(!isOpen)}
        style={{ cursor: 'pointer' }}
        className="d-flex justify-content-between align-items-center"
      >
        <div>
          <FaBoxOpen style={{ marginRight: '8px' }} />
          Installations
        </div>
        {isOpen ? <FaChevronDown /> : <FaChevronRight />}
      </h3>

      <Collapse in={isOpen}>
        <div>
          {installations && installations.length > 0 ? (
            <div className="table-responsive">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Version</th>
                    <th>Total Installs</th>
                  </tr>
                </thead>
                <tbody>
                  {installations.map((d) => (
                    <tr key={d._id}>
                      <td>{d._id}</td>
                      <td>{d.totalCount.toLocaleString()}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <EmptyState message="No installation data available" />
          )}
        </div>
      </Collapse>
    </div>
  );
};

const SubscriptionsTable = ({ subscriptions }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  // Filter subscriptions based on search term
  const filteredSubscriptions = subscriptions?.filter(sub => 
    sub.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    sub.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    sub.customerId.toLowerCase().includes(searchTerm.toLowerCase()) ||
    sub.planNickName.toLowerCase().includes(searchTerm.toLowerCase())
  ) || [];

  // Sort subscriptions
  const sortedSubscriptions = [...filteredSubscriptions].sort((a, b) => {
    if (!sortConfig.key) return 0;
    
    const aValue = a[sortConfig.key];
    const bValue = b[sortConfig.key];
    
    if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
    if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
    return 0;
  });

  // Calculate pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedSubscriptions.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(sortedSubscriptions.length / itemsPerPage);

  // Calculate plan summary
  const planSummary = subscriptions?.reduce((acc, sub) => {
    acc[sub.planNickName] = (acc[sub.planNickName] || 0) + 1;
    return acc;
  }, {}) || {};

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (columnName) => {
    if (sortConfig.key !== columnName) return <FaSort />;
    return sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />;
  };

  return (
    <div className="summary-section">
      <h3>
        <FaCreditCard style={{ marginRight: '8px' }} />
        Subscriptions
      </h3>

      <div className="plan-summary-grid">
        {Object.entries(planSummary).map(([plan, count]) => (
          <StatCard key={plan} title={plan} value={count} />
        ))}
      </div>

      <div className="table-controls">
        <InputGroup className="mb-3">
          <InputGroup.Text>
            <FaSearch />
          </InputGroup.Text>
          <Form.Control
            placeholder="Search by email, name, customer ID, or plan..."
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setCurrentPage(1);
            }}
          />
        </InputGroup>
      </div>

      {currentItems.length > 0 ? (
        <>
          <div className="table-responsive">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th onClick={() => requestSort('customerId')} style={{ cursor: 'pointer' }}>
                    Customer Id {getSortIcon('customerId')}
                  </th>
                  <th onClick={() => requestSort('planNickName')} style={{ cursor: 'pointer' }}>
                    Plan Nickname {getSortIcon('planNickName')}
                  </th>
                  <th onClick={() => requestSort('lastFour')} style={{ cursor: 'pointer' }}>
                    Last Four {getSortIcon('lastFour')}
                  </th>
                  <th onClick={() => requestSort('name')} style={{ cursor: 'pointer' }}>
                    Name {getSortIcon('name')}
                  </th>
                  <th onClick={() => requestSort('email')} style={{ cursor: 'pointer' }}>
                    Email {getSortIcon('email')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((d) => (
                  <tr key={d.stripeSubscriptionId}>
                    <td>{d.customerId}</td>
                    <td>{d.planNickName}</td>
                    <td>{d.lastFour}</td>
                    <td>{d.name}</td>
                    <td>{d.email}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          <div className="pagination-controls">
            <div className="pagination-info">
              Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, sortedSubscriptions.length)} of {sortedSubscriptions.length} entries
            </div>
            <div className="pagination-buttons">
              <Button
                variant="outline-secondary"
                disabled={currentPage === 1}
                onClick={() => setCurrentPage(prev => prev - 1)}
              >
                Previous
              </Button>
              {Array.from({ length: totalPages }, (_, i) => i + 1).map(number => (
                <Button
                  key={number}
                  variant={currentPage === number ? "primary" : "outline-secondary"}
                  onClick={() => setCurrentPage(number)}
                >
                  {number}
                </Button>
              ))}
              <Button
                variant="outline-secondary"
                disabled={currentPage === totalPages}
                onClick={() => setCurrentPage(prev => prev + 1)}
              >
                Next
              </Button>
            </div>
          </div>
        </>
      ) : (
        <EmptyState message={searchTerm ? "No matching subscriptions found" : "No subscription data available"} />
      )}
    </div>
  );
};

const SummaryScreen = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const summaryState = useSelector((state) => state.summaryState);
  const { summaryData, error, loading } = summaryState;

  useEffect(() => {
    if (!summaryData && userInfo && userInfo.token) {
      dispatch(getSummary(userInfo.token, userInfo.refreshToken));
    }
  }, [dispatch, summaryData, userInfo]);

  if (loading) return <Loader />;
  if (error) return <Message variant="danger">{error}</Message>;

  const totalInstalls = summaryData?.countBySettings.reduce(
    (acc, curr) => acc + curr.totalCount,
    0
  ) || 0;

  const totalSubscribers = summaryData?.subscribers.length || 0;

  return (
    <div className="summary">
      <h1 className="text-center">Summary Dashboard</h1>
      
      <div className="stats-grid">
        <StatCard 
          title="Total Installations" 
          value={totalInstalls.toLocaleString()} 
        />
        <StatCard 
          title="Active Subscribers" 
          value={totalSubscribers.toLocaleString()} 
        />
      </div>

      {loading ? (
        <LoadingSkeleton />
      ) : (
        <>
          <InstallationsTable 
            installations={summaryData?.countBySettings} 
          />
          <SubscriptionsTable 
            subscriptions={summaryData?.subscribers} 
          />
        </>
      )}
    </div>
  );
};

export default SummaryScreen;
