import React from 'react';
import { Button } from 'react-bootstrap';

const Pagination = ({ 
  limit, 
  currentPage, 
  pageCount, 
  pagination, 
  onLimitChange, 
  onPageChange, 
  onPrevPage, 
  onNextPage 
}) => {
  const limitSelector = () => {
    const options = [
      { value: 10 },
      { value: 20 },
      { value: 50 },
      { value: 75 },
      { value: 100 },
    ];
    return (
      <select value={limit} onChange={onLimitChange}>
        {options.map(({ value }, index) => (
          <option key={index} value={value}>
            {value}
          </option>
        ))}
      </select>
    );
  };

  const pageSelector = () => {
    const options = [];
    for (let i = 1; i <= pageCount; i++) {
      options.push({ value: i });
    }

    return (
      <select value={currentPage} onChange={onPageChange}>
        {options.map(({ value }, index) => (
          <option key={index} value={value}>
            {value}
          </option>
        ))}
      </select>
    );
  };

  return (
    <div className="pagination">
      <span>Show {limitSelector()} videos</span>
      <Button
        className="pagination-btn prev"
        disabled={!pagination.prev}
        onClick={onPrevPage}
      >
        <span className="btn-label"> Prev</span>
      </Button>
      <span className="page-num">Page:</span>
      {pageSelector()}
      <Button
        className="pagination-btn next"
        disabled={!pagination.next}
        onClick={onNextPage}
      >
        <span className="btn-label"> Next</span>
      </Button>
    </div>
  );
};

export default Pagination;
