import React from 'react';
import { Button } from 'react-bootstrap';

const QueryBar = ({ onQueryTextChange, onQuerySubmit }) => {
  return (
    <div className="query">
      <span className="controls-label">Query:</span>
      <input
        type="text"
        name="query"
        id="query"
        onChange={onQueryTextChange}
      />
      <Button className="btn-query" onClick={onQuerySubmit}>
        Run
      </Button>
      <p className="query-help">
        Ex: author=Scotland (Avail. fields: name, title, author, duration,
        _id, selectedCount, favoriteCount, dislikecount) (Avail. operators
        for number: gt, gte, lt, lte. Ex. duration[gte]=8)
      </p>
    </div>
  );
};

export default QueryBar;
