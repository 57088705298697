import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Button, Form, Card } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import FormContainer from "../components/FormContainer";
import { forgotPassword } from "../actions/userActions";
import Message from "../components/Message";
import Loader from "../components/Loader";
import "./LoginScreen.css";

const ForgotPasswordScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const forgotPasswordState = useSelector((state) => state.userForgotPassword);
  const { userInfo } = userLogin;
  const { error, loading, result } = forgotPasswordState;

  useEffect(() => {
    if (userInfo && userInfo.token) {
      navigate("/");
    }
  }, [navigate, userInfo]);

  const resetPasswordHandler = async (email) => {
    dispatch(forgotPassword(email));
  };

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: yup.object({
      email: yup.string().required("Email is required").email("Invalid Email"),
    }),
    onSubmit: (values) => resetPasswordHandler(values.email),
  });

  return (
    <FormContainer>
      <Card className="login-card shadow-sm border-0 p-3">
        <Card.Body>
          <h2 className="text-center mb-4">Reset Password</h2>
          <p className="text-center text-muted mb-4">
            Enter your email address and we'll send you instructions to reset your password.
          </p>
          
          {error && <Message variant="danger">{error}</Message>}
          {loading && <Loader />}
          {result && result.success ? (
            <div className="text-center">
              <Message variant="success">{result.data}</Message>
              <LinkContainer to="/login">
                <Button variant="primary" className="rounded-pill mt-3">
                  Return to Login
                </Button>
              </LinkContainer>
            </div>
          ) : (
            <Form noValidate onSubmit={formik.handleSubmit}>
              <Form.Group controlId="email" className="mb-4">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  className="rounded-pill"
                  isInvalid={formik.touched.email && formik.errors.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.email}
                </Form.Control.Feedback>
              </Form.Group>

              <div className="d-grid gap-2">
                <Button
                  variant="primary"
                  type="submit"
                  className="rounded-pill py-2 mb-3"
                  disabled={formik.isSubmitting || loading}
                >
                  {formik.isSubmitting || loading
                    ? "Please wait..."
                    : "Reset Password"}
                </Button>
              </div>

              <div className="text-center">
                <LinkContainer to="/login">
                  <Button
                    variant="link"
                    className="text-decoration-none"
                    disabled={formik.isSubmitting || loading}
                  >
                    Back to Login
                  </Button>
                </LinkContainer>
              </div>
            </Form>
          )}
        </Card.Body>
      </Card>
    </FormContainer>
  );
};

export default ForgotPasswordScreen;
