import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Loader from "./Loader";

import * as constants from "../constants/commonConstants";
import "./Modal.css";

const Modal = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(props.videoInfo?.videoIsActive || false);
  
  React.useEffect(() => {
    setIsActive(props.videoInfo?.videoIsActive || false);
  }, [props.videoInfo?.videoIsActive]);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const toggleVideoActive = async (isActive) => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${constants.BASE_API_URL}/videos/${props.videoInfo.videoId}`,
        {
          method: "PUT",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${props.token}`,
          },
          body: JSON.stringify({ active: isActive }),
        }
      );
      const result = await response.json();
      if (result.success === true) {
        setIsActive(isActive);
        props.videoActiveChanged(props.videoInfo.videoId, isActive);
      } else {
        console.log("Something went wrong");
      }
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  const deactivateVideo = (e) => {
    e.stopPropagation();
    toggleVideoActive(false);
  };

  const activateVideo = (e) => {
    e.stopPropagation();
    toggleVideoActive(true);
  };

  const handleVideoLoaded = () => {
    setIsVideoLoaded(true);
  };

  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="modal-container" id="modal" onClick={props.closeModal}>
      <div className="video-modal" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h3>
            {props.videoInfo.videoName} - {props.videoInfo.videoTitle} -{" "}
            {props.videoInfo.videoAuthor}
          </h3>
          <button className="close-btn" id="close" onClick={props.closeModal}>
            <span className="material-icons">close</span>
          </button>
        </div>
        <div className="modal-content">
          <div className="modal-video-container">
            <div className={`loading-container ${isVideoLoaded ? 'fade-out' : ''}`}>
              <Loader size="md" variant="primary" />
              <p>Loading your video...</p>
            </div>
            <video 
              className="modal-video" 
              autoPlay 
              loop
              onLoadedData={handleVideoLoaded}
            >
              <source
                type="video/mp4"
                src={props.videoInfo.videoFileSource}
              ></source>
            </video>
          </div>
          {isVideoLoaded && (
            <div className="video-details">
              <div className="video-stats">
                <div className="stat-item">
                  <span className="material-icons">timer</span>
                  {formatDuration(props.videoInfo.videoDuration)}
                </div>
                <div className="stat-item">
                  <span className="material-icons">check_circle</span>
                  {props.videoInfo.selectedCount} selected
                </div>
                <div className="stat-item">
                  <span className="material-icons">favorite</span>
                  {props.videoInfo.favoriteCount} favorites
                </div>
                <div className="stat-item">
                  <span className="material-icons">thumb_down</span>
                  {props.videoInfo.dislikeCount} dislikes
                </div>
              </div>
              <div className="video-tags">
                {props.videoInfo.videoTags && props.videoInfo.videoTags.map((tag, index) => (
                  <span key={index} className="tag">{tag}</span>
                ))}
              </div>
            </div>
          )}
          <div className="modal-vid-info">
            <div className="vid-info-save">
              {isActive ? (
                <Button
                  className="save-btn"
                  variant="danger"
                  onClick={deactivateVideo}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    "Processing..."
                  ) : (
                    <>
                      <span className="material-icons" style={{ fontSize: '16px', marginRight: '4px', verticalAlign: 'text-top' }}>
                        block
                      </span>
                      Mark as Unselectable
                    </>
                  )}
                </Button>
              ) : (
                <Button
                  className="save-btn"
                  variant="primary"
                  onClick={activateVideo}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    "Processing..."
                  ) : (
                    <>
                      <span className="material-icons" style={{ fontSize: '16px', marginRight: '4px', verticalAlign: 'text-top' }}>
                        check_circle
                      </span>
                      Mark as Selectable
                    </>
                  )}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
