import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useFormik } from "formik";
import { Form, Button, Card } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";

import { login, registerReset } from "../actions/userActions";
import FormContainer from "../components/FormContainer";
import Message from "../components/Message";
import Loader from "../components/Loader";

const LoginScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  const userResetPassword = useSelector((state) => state.userResetPassword);
  const { result: passwordResetResult } = userResetPassword;

  const searchParams = new URLSearchParams(location.search);
  const redirect = searchParams.get("redirect");

  useEffect(() => {
    if (userInfo && userInfo.token) {
      if (redirect === "upgrade") {
        navigate(`/${redirect}`);
      } else {
        navigate("/");
      }
    }
  }, [navigate, redirect, userInfo]);

  useEffect(() => {
    dispatch(registerReset());
  }, [dispatch]);

  const schemaSignIn = yup.object({
    emailSignIn: yup
      .string()
      .required("Email is required")
      .email("Please enter a valid email"),
    passwordSignIn: yup
      .string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
  });

  const forgotPasswordHandler = (e) => {
    e.preventDefault();
    navigate("/forgotpassword");
  };

  const signinHandler = async (email, password) => {
    dispatch(login(email, password));
  };

  const siFormik = useFormik({
    initialValues: { emailSignIn: "", passwordSignIn: "" },
    validationSchema: schemaSignIn,
    onSubmit: (values) =>
      signinHandler(values.emailSignIn, values.passwordSignIn),
  });

  return (
    <FormContainer>
      <Card className="login-card shadow-sm border-0 p-3">
        <Card.Body>
          <h2 className="text-center mb-4">Welcome Back</h2>
          {passwordResetResult && passwordResetResult.success && (
            <div>
              <Message variant="success">
                Your password has been reset successfully.
              </Message>
              <Message variant="info">
                Please login below to view your account/upgrade.
                <br /> Or open a new UseDelight tab and login from there to access
                your subscription benefits.
              </Message>
            </div>
          )}
          {error && (
            <Message variant="danger">
              {error === "Invalid credentials"
                ? "Incorrect email or password"
                : error}
            </Message>
          )}
          {loading && <Loader />}
          <Form noValidate onSubmit={siFormik.handleSubmit}>
            <Form.Group controlId="emailSignIn" className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                name="emailSignIn"
                className="rounded-pill"
                isInvalid={
                  siFormik.touched.emailSignIn && siFormik.errors.emailSignIn
                }
                onChange={siFormik.handleChange}
                onBlur={siFormik.handleBlur}
                value={siFormik.values.emailSignIn}
              />
              <Form.Control.Feedback type="invalid">
                {siFormik.errors.emailSignIn}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="passwordSignIn" className="mb-4">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter your password"
                name="passwordSignIn"
                className="rounded-pill"
                isInvalid={
                  siFormik.touched.passwordSignIn && siFormik.errors.passwordSignIn
                }
                onChange={siFormik.handleChange}
                onBlur={siFormik.handleBlur}
                value={siFormik.values.passwordSignIn}
              />
              <Form.Control.Feedback type="invalid">
                {siFormik.errors.passwordSignIn}
              </Form.Control.Feedback>
            </Form.Group>

            <div className="d-grid gap-2">
              <Button
                variant="primary"
                type="submit"
                className="rounded-pill py-2 mb-3"
                disabled={loading}
              >
                Sign In
              </Button>
            </div>

            <div className="text-center">
              <LinkContainer
                to={redirect ? `/register?redirect=${redirect}` : "/register"}
              >
                <Button variant="link" className="text-decoration-none">
                  New user? Create an account
                </Button>
              </LinkContainer>
            </div>

            <div className="text-center mt-2">
              <Button
                variant="link"
                onClick={forgotPasswordHandler}
                className="text-decoration-none text-muted"
              >
                Forgot password?
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </FormContainer>
  );
};

export default LoginScreen;
