import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { FaUsers, FaHome, FaPuzzlePiece, FaCog, FaMapMarkerAlt, FaStar, FaHistory, FaSync } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import * as constants from "../constants/commonConstants";
import Loader from "../components/Loader";
import "./StatsScreen.css";

const StatsScreen = () => {
  const navigate = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isReady, setIsReady] = useState(false);

  const loadStats = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${constants.BASE_API_URL}/stats`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      });

      const result = await response.json();
      if (result.success === true) {
        setData(result.data);
      }
    } catch (error) {
      console.error('Failed to load stats:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const refreshData = () => {
    loadStats();
  };

  const autoRefresh = () => {
    setInterval(() => {
      if (!isLoading) {
        loadStats();
      }
    }, 300000);
  };

  useEffect(() => {
    if (!userInfo || !userInfo.token) {
      navigate("/login");
    } else {
      setIsReady(true);
    }
  }, [navigate, userInfo]);

  useEffect(() => {
    if (isReady) {
      loadStats();
      autoRefresh();
    }
  }, [isReady]);

  const items = () => {
    return (
      <div className="items">
        <div className="item">
          <div className="item-label"><FaUsers /> Total Users</div>
          <div className="item-value">{data.totalUsers?.toLocaleString() || '0'}</div>
        </div>
        <div className="item">
          <div className="item-label"><FaHome /> Home Users</div>
          <div className="item-value">{data.homeUsers?.toLocaleString() || '0'}</div>
        </div>
        <div className="item">
          <div className="item-label"><FaPuzzlePiece /> Extension Users</div>
          <div className="item-value">
            {data.chromeExtUsers?.toLocaleString() || '0'}
          </div>
        </div>
        <div className="item">
          <div className="item-label"><FaCog /> Settings</div>
          <div className="item-value">
            {data.totalSettings?.toLocaleString() || '0'}
          </div>
        </div>
        <div className="item">
          <div className="item-label"><FaMapMarkerAlt /> Locations</div>
          <div className="item-value">
            {data.totalLocations?.toLocaleString() || '0'}
          </div>
        </div>
        <div className="item">
          <div className="item-label"><FaStar /> Ratings</div>
          <div className="item-value">{data.totalRatings?.toLocaleString() || '0'}</div>
        </div>
        <div className="item">
          <div className="item-label"><FaHistory /> Video History</div>
          <div className="item-value">
            {data.totalVideoHistories?.toLocaleString() || '0'}
          </div>
        </div>
        <div className="items-footer">
          Last fetched: {`${new Date().toLocaleString()}`}
        </div>
        <div className="items-refresh">
          <Button
            className="refresh-btn"
            onClick={refreshData}
            disabled={isLoading}
          >
            {isLoading ? (
              <div className="loader-wrapper">
                <Loader size="sm" variant="light" />
              </div>
            ) : (
              <>
                <FaSync />
                Refresh Stats
              </>
            )}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="stats">
      <h1 className="stats-title font-weight-bold">Stats</h1>

      {isLoading ? (
        <Loader size="md" variant="primary" />
      ) : (
        items()
      )}
    </div>
  );
};

export default StatsScreen;
