import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import * as constants from "../constants/commonConstants";
import Video from "../components/Video";
import Modal from "../components/Modal";
import Pagination from "../components/Pagination";
import FilterBar from "../components/FilterBar";
import SortBar from "../components/SortBar";
import QueryBar from "../components/QueryBar";
import Loader from "../components/Loader";

import "./GalleryScreen.css";

const GalleryScreen = () => {
  const navigate = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [isLoading, setIsLoading] = useState(true);
  const [videos, setVideos] = useState([]);
  const [count, setCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [pagination, setPagination] = useState({});
  const [selected, setSelected] = useState({});
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(constants.RESULT_LIMIT);
  const [activeTag, setActiveTag] = useState("");
  const [activeSort, setActiveSort] = useState("");
  const [activeQuery, setActiveQuery] = useState("");
  const [isReady, setIsReady] = useState(false);

  let filters = "&";
  let sort = "&";
  let query = "&";

  useEffect(() => {
    if (!userInfo || !userInfo.token) {
      navigate("/login");
    } else {
      setIsReady(true);
    }
  }, [navigate, userInfo]);

  const loadVideos = async (limitParam, page) => {
    if (!limitParam) {
      limitParam = `limit=${limit}`;
    }

    if (!page) {
      page = `&page=${currentPage}`;
    }

    try {
      setIsLoading(true);
      const response = await fetch(
        `${constants.BASE_API_URL}/videos/nature?${limitParam}${page}${filters}${sort}${query}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );

      const result = await response.json();
      if (result.success === true) {
        let pCount = Math.ceil(result.total / limit);
        if (pCount === 0) {
          pCount = 1;
        }

        setVideos(result.data);
        setPagination(result.pagination);
        setCount(result.count);
        setTotal(result.total);
        setPageCount(pCount);
        setCurrentPage(
          result.pagination && result.pagination.next
            ? result.pagination.next.page - 1
            : 1
        );
        setIsLoading(false);
      }
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isReady) {
      loadVideos();
    }
  }, [isReady]);

  const loadPrevPage = () => {
    loadVideos(null, `&page=${pagination.prev.page}`);
  };

  const loadNextPage = () => {
    loadVideos(null, `&page=${pagination.next.page}`);
  };

  const onPageChangeHandler = (e) => {
    loadVideos(null, `&page=${e.target.value}`);
  };

  const onLimitChangeHandler = (e) => {
    const limitValue = e.target.value;
    setLimit(limitValue);
    setCurrentPage(1);
    loadVideos(`limit=${limitValue}`, `&page=1`);
  };

  const tagFilterHandler = (tag) => {
    if (tag === activeTag) {
      tag = "";
      filters = "&";
    } else {
      filters = `&tags[in]=${tag}`;
    }
    setActiveTag(tag);
    loadVideos();
  };

  const noTagFilterHandler = () => {
    let tag = "";
    if (activeTag === "no-tag") {
      tag = "";
      filters = "&";
    } else {
      tag = "no-tag";
      filters = "&tags[size]=0";
    }

    setActiveTag(tag);
    loadVideos();
  };

  const mostSelectedSortHandler = () => {
    let aSort = "";
    if (activeSort === "most-selected") {
      sort = "&";
      aSort = "";
    } else {
      sort = `&sort=-selectedCount,-favoriteCount,dislikeCount`;
      aSort = "most-selected";
    }

    setActiveSort(aSort);
    loadVideos();
  };

  const leastSelectedSortHandler = () => {
    let aSort = "";
    if (activeSort === "least-selected") {
      sort = "&";
      aSort = "";
    } else {
      sort = `&sort=selectedCount,-favoriteCount,dislikeCount`;
      aSort = "least-selected";
    }

    setActiveSort(aSort);
    loadVideos();
  };

  const topFavoriteSortHandler = () => {
    let aSort = "";
    if (activeSort === "top-favorite") {
      sort = "&";
      aSort = "";
    } else {
      sort = `&sort=-favoriteCount,dislikeCount,selectedCount`;
      aSort = "top-favorite";
    }

    setActiveSort(aSort);
    loadVideos();
  };

  const mostDislikedSortHandler = () => {
    let aSort = "";
    if (aSort === "most-disliked") {
      sort = "&";
      aSort = "";
    } else {
      sort = `&sort=-dislikeCount,favoriteCount,selectedCount`;
      aSort = "most-disliked";
    }

    setActiveSort(aSort);
    loadVideos();
  };

  let queryText = "";
  const queryTextChangeHandler = (e) => {
    queryText = e.target.value;

    if (queryText.trim().length === 0) {
      query = "&";
      setActiveQuery(queryText);
    }
  };

  const queryHandler = () => {
    query = `&${queryText}`;
    setActiveQuery(queryText);
    loadVideos();
  };

  const previewVideoHandler = (vidInfo) => {
    setSelected(vidInfo);
  };

  const closeModalHandler = (e) => {
    if (
      e.target.classList.contains("modal-container") ||
      e.target.classList.contains("close-btn") ||
      e.target.classList.contains("material-icons")
    ) {
      setSelected({});
    } else {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const videoActiveChangedHandler = (videoId, isActive) => {
    const updatedVideos = videos;
    const videoToUpdate = updatedVideos.find((v) => v.id === videoId);
    videoToUpdate.active = isActive;
    setVideos(updatedVideos);
  };

  const vids = videos.map((v, index) => {
    return (
      <Video
        key={index}
        id={v.id}
        videoIsActive={v.active}
        videoName={v.name}
        videoTitle={v.title}
        videoAuthor={v.author}
        videoTags={v.tags}
        videoDuration={v.duration}
        selectedCount={v.selectedCount}
        favoriteCount={v.favoriteCount}
        dislikeCount={v.dislikeCount}
        fileName={v.fileName}
        preview={v.preview}
        previewVideo={previewVideoHandler}
        token={userInfo.token}
      />
    );
  });

  const loading = <div className="loading"><Loader /></div>;

  const content = isLoading ? (
    loading
  ) : (
    <div>
      <div className="videos">{vids}</div>
      <Pagination
        limit={limit}
        currentPage={currentPage}
        pageCount={pageCount}
        pagination={pagination}
        onLimitChange={onLimitChangeHandler}
        onPageChange={onPageChangeHandler}
        onPrevPage={loadPrevPage}
        onNextPage={loadNextPage}
      />
    </div>
  );

  return (
    isReady && (
      <div className="gallery">
        <h1 className="gallery-title">Nature Videos Gallery</h1>
        <div className="controls-container">
          <FilterBar
            activeTag={activeTag}
            onTagFilter={tagFilterHandler}
            onNoTagFilter={noTagFilterHandler}
          />
          <SortBar
            activeSort={activeSort}
            onMostSelectedSort={mostSelectedSortHandler}
            onLeastSelectedSort={leastSelectedSortHandler}
            onTopFavoriteSort={topFavoriteSortHandler}
            onMostDislikedSort={mostDislikedSortHandler}
          />
          <QueryBar
            onQueryTextChange={queryTextChangeHandler}
            onQuerySubmit={queryHandler}
          />
        </div>
        {content}
        {selected && selected.videoFileSource ? (
          <Modal
            videoInfo={selected}
            closeModal={closeModalHandler}
            token={userInfo.token}
            videoActiveChanged={videoActiveChangedHandler}
          />
        ) : null}
      </div>
    )
  );
};

export default GalleryScreen;
