import React from 'react';

const SortBar = ({ 
  activeSort, 
  onMostSelectedSort, 
  onLeastSelectedSort, 
  onTopFavoriteSort, 
  onMostDislikedSort 
}) => {
  return (
    <div className="sort">
      <span className="controls-label">Sort by:</span>
      <button
        className={activeSort === "most-selected" ? "btn-sort active" : "btn-sort"}
        onClick={onMostSelectedSort}
      >
        Most Selected
      </button>
      <button
        className={activeSort === "least-selected" ? "btn-sort active" : "btn-sort"}
        onClick={onLeastSelectedSort}
      >
        Least Selected
      </button>
      <button
        className={activeSort === "top-favorite" ? "btn-sort active" : "btn-sort"}
        onClick={onTopFavoriteSort}
      >
        Top Favorites
      </button>
      <button
        className={activeSort === "most-disliked" ? "btn-sort active" : "btn-sort"}
        onClick={onMostDislikedSort}
      >
        Most Disliked
      </button>
    </div>
  );
};

export default SortBar;
